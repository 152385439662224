.wrapper {
  width: 100vw;
  height: 100vh;
}
.background-screen {
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.splash-content {
  width: 100vw;
  height: 100vh;
  display: flex;
}
.genomics-logo {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
}
.main-content {
  height: 100vh;
  width: 100vw;
  position: relative;
}

/*Slide related*/
.close-left-slide {
  cursor: pointer;
}

.slideout-title {
  font-family: sofia-pro;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 23px;
  color: #525a60;
}

.slide-out {
  width: 30%;
  height: 100%;
  right: -30%;
  top: 0;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  z-index: 999;
}

.slide-out.video-mode {
  width: 50%;
  right: -50%;
  z-index: 99;
  background-color: rgba(82, 90, 96, 0.6);
}

.slide-in {
  transform: translateX(-100%);
  transition: transform 400ms ease-in;
  -webkit-transform: translate3d(-100%, 0, 0);
}

.slide-away {
  transform: translateX(100%);
  transition: transform 400ms ease-in;
}

.slide-slow {
  transition-duration: 1000ms;
}

.resource-list {
  padding: 0;
}

.resource-list li {
  list-style: none;
}

.resource-link {
  font-family: sofia-pro;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: #00a1df;
}

.resource-link:hover {
  text-decoration: none;
  color: #00a1df;
}

.classic {
  padding: 0.8em 1em;
}
.custom {
  padding: 0.5em 0.8em 0.8em 2em;
}
* html a:hover {
  background: transparent;
}

/* Popover */
.popover {
  background-color: #6D6E71 !important;
  color: #fff !important;
  border-radius: 0em;
  font-family: sofia-pro !important;
  max-width: 35em !important;
  min-width: 20em;
}

/* Popover Header */
.popover-title {
  font-family: sofia-pro !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  font-feature-settings: "liga" off;
  color: #ffffff !important;
  background-color: #6D6E71 !important;
  text-align: center;
}

/* Popover Body */
.popover-content {
  background-color: #6D6E71 !important;
  color: #ffffff !important;
  font-family: sofia-pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  font-feature-settings: "liga" off;
}

/* Popover Arrow */
.arrow:after {
  border-right-color: black !important;
}

.popover-header {
  padding: 0.7rem 0.75rem 0rem 0.5rem;
  margin-bottom: 0;
  font-size: 2.25rem;
  color: inherit;
  background-color: #6D6E71;
  border-bottom: 1px solid #6D6E71;
  font-weight: bold;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #fff;
  white-space: pre-line;
}

.bs-popover-auto[x-placement^="left"] .arrow::after,
.bs-popover-left .arrow::after {
  right: 1px;
  border-left-color: #000 !important;
}

.transition-enabled {
  position: absolute;
  left: 0;
  top: 0;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 800ms linear 1500ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 800ms linear;
}

#rollover-instructions {
  height: fit-content;
  margin-left: 2rem;
  height: 10rem;
  width: fit-content;
}

#rollover-image {
  width: 100%;
  padding: 2rem;
}

#rollover-text {
  width: fit-content;
}

.rolloverinstructions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltip-image {
  cursor: pointer;
}